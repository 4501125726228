<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.pengirim+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId == chatData.owner}"
    >
      <div class="chat-avatar">
        <b-avatar
          class="avatar-border-2 box-shadow-1"
          variant="primary"
          :text="avatarText(msgGrp.senderId || msgGrp.sender)"
        />
      </div>
      <div class="chat-body">
        
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.time"
          class="chat-content"
        >
        <div v-if="msgGrp.senderId == chatData.owner" class="form-group form-check" style="margin-bottom:20px">
          <input type="checkbox" v-model="datacheklist" class="form-check-input" :id="msgData.id" :value='msgData' @change="$emit('getPertanyaan',datacheklist)">
          <!-- <label :for="msgData.id">{{msgData.msg}}</label> -->
        </div>
        <div v-if="cekEkstensi(msgData.msg) === 'jpeg'">
          <img :src="msgData.msg" class="img-thumbnail" :alt="msgData.msg">
        </div>
        <div v-else-if="cekEkstensi(msgData.msg) === 'pdf'">
          <a :href="msgData.msg" target="_blank">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            type="submit"
          >
            <feather-icon
              icon="FileTextIcon"
              class="mr-50"
            />
            <i data-feather='file'></i>
            <span class="align-middle">{{getNamaFile(msgData.msg)}}</span>
          </b-button>
          </a>
         
        </div>
        <div v-else>
          
          <p>{{msgData.msg }}</p>
          <span v-if="msgGrp.senderId == chatData.owner">
            <b-badge :variant="statusVariant(msgData.status)">
            {{msgData.status}}
          </b-badge>
          </span>
        </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed,ref } from '@vue/composition-api'
import { BAvatar, BIcon, BButton, BBadge } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BIcon,
    BButton,
    BBadge
  },
  data(){
    return{
      checkData: [],
      userData : JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    // checkData:{
    //   type:Array,
    //   required:true
    // },
    chatData: {
      type: Object,
      required: true,
    },
    // profileUserAvatar: {
    //   type: String,
    //   required: true,
    // },
  },
  created() {
    // each instance now has its own copy of debounced handler
    this.checkData = []
  },
  methods:{
    getNamaFile(input){
      return input.split(/[/ ]+/).pop();
    },
    cekEkstensi(input){
      return input.split(/[. ]+/).pop();
    }
  },
  setup(props) {
    const datacheklist = ref([])
    const statusVariant = computed(() =>{
      const statusColor = {
        /* eslint-disable key-spacing */
        "Answered by Expert"      : 'light-primary',
        "Resolved" : 'light-success',
        "Returned by Expert"     : 'light-danger',
        "Answered"      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    })
    const formattedChatData = computed(() => {
      datacheklist.value = []
      const contact = {
        id: props.chatData.id,
        avatar: props.chatData.owner,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].pengirim : undefined
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      }
      console.log(formattedChatData)

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.pengirim) {
          msgGroup.messages.push({
            id: msg.id,
            msg: msg.pesan,
            time: msg.created_at,
            status: msg.status
          })
        } else {
          chatMessageSenderId = msg.pengirim
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.pengirim,
            messages: [{
              id: msg.id,
              msg: msg.pesan,
              time: msg.created_at,
              status: msg.status
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        // profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
      avatarText,
      datacheklist,
      statusVariant
    }
  },
}
</script>

<style>

</style>
